import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slideshow.css";

const Slideshow = (props) => {
    const albumData = props.albumData;
    const photosCount = albumData.photosCount;
    
    const getPhotosContent = () => {
        let content = [];
        for (let i = 1; i < (photosCount+1); i++) {
            content.push(
                <div className="SlideshowPhotoContainer" key={i}>
                    <div className="SlideshowPhoto" style={{ backgroundImage: `url(/photo_albums/${albumData.type}/${albumData.key}/${albumData.id}/${i}.JPG)` }}>
                        {/* <span>Slide {index + 1}</span> */}
                    </div>
                </div>
            )
        }
        return content;
    }
    return (
        <div className="SliderPage">
            <div className="Slider">
                <Slide easing="ease" >
                    {getPhotosContent()}
                </Slide>
            </div>
        </div>
    );
}

export default Slideshow;