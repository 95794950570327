import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import appConfig from "./configuration"; // Assuming the correct path to your configuration file
import { getDatabase, ref, onValue } from "firebase/database";

import './App.css';
import Home from './Home';
import Sights from './pages/Sights/Sights';
import DigitalResources from './pages/DigitalResources/DigitalResources';
import Project from './pages/About/Project';
import AreaInfo from './pages/About/AreaInfo';
import Sight from './pages/Sights/Sight';
import Themes from './pages/Sights/Themes';
import Contact from './pages/Contact/Contact';
import News from './pages/News/News';

import menuIcon from './assets/img/menu.png'
import leftArrow from './assets/img/left-arrow.png'
import NavBar from './components/Accordion/Accordion';
import Idea from './pages/Idea/Idea';
import Team from './pages/Team/Team';
import SideMenu from './components/SideMenu/SideMenu';
import PhotoAlbums from './pages/PhotoAlbum/PhotoAlbums';
import CalendarPage from './pages/Calendar/Calendar';
import OnFocus from './pages/OnFocus/OnFocus';
import PhotoAlbum from './pages/PhotoAlbum/PhotoAlbum';

const Header = () => {
	const [openMenuStyle, setOpenMenuStyle] = useState({});
	const [openMenuIcon, setOpenMenuIcon] = useState(menuIcon);
	const [data, setData] = useState([]);

	useEffect(() => {
		// Initialize the Firebase database with the provided configuration
		const database = getDatabase(appConfig);

		// Reference to the specific collection in the database
		const collectionRef = ref(database, "objects");
	
		// Function to fetch data from the database
		const fetchData = () => {
			// Listen for changes in the collection
			onValue(collectionRef, (snapshot) => {
			const dataItem = snapshot.val();
	
			// Check if dataItem exists
			if (dataItem) {
				// Convert the object values into an array
				const displayItem = Object.values(dataItem);
				
				setData(displayItem);
			}
			});
		};
	
		// Fetch data when the component mounts
		fetchData();
	}, [])

	const expandMenu = () => {
		if (openMenuStyle.left === 0) { // opened
			setOpenMenuStyle({
				left: '-40vw'
			})
			setOpenMenuIcon(
				menuIcon
			)
		}
		else { // closed
			setOpenMenuStyle({
				left: 0,
			})
			setOpenMenuIcon(
				leftArrow
			)
		}
	}

	return (
		<header className="App-header">
			<Link className="App-header-logo" to={"/"}><img src={require("./assets/img/logo/logo3.png")} /></Link>
			<img className="Menu-icon" onClick={expandMenu} src={openMenuIcon}/>
			<div className="Menu" style={openMenuStyle}>
				<SideMenu/>
			</div>
			<div className="App-header-nav-items">
				<NavBar/>
			</div>
		</header>
	)
}

const Footer = () => {
	return (
		<div className="App-footer">
			<h3>Проектът се реализира с подкрепата на:</h3>
			<div className="Sponsors-logos">
				<a href='https://bnsf.bg/wps/portal/fond-izsledvaniq/home' target="_blank"><img src={require("./assets/img/sponsors/1.png")}/></a>
				<a href='https://www.balkanstudies.bg/bg/' target="_blank"><img src={require("./assets/img/sponsors/2.png")}/></a>
				<a href='https://www.bas.bg/' target="_blank"><img src={require("./assets/img/sponsors/3.png")}/></a>
			</div>
		</div>
	)
}
const App = () => {

	return (
		<div className="App">
			<BrowserRouter>
			<Header/>
			<Routes>
				<Route path="/" element={<Home/>}/>
				<Route exact path="/themes/:type/:themeId" element={<Themes/>}/>
				<Route exact path="/themes/:type/:themeId/:sightsId" element={<Sights/>}/>
				<Route path="/sights/:sightId" element={<Sight/>}/>
				<Route path="/on-focus" element={<OnFocus/>}/>
				<Route path="/digital-resources" element={<DigitalResources/>}/>
				<Route path="/calendar" element={<CalendarPage/>}/>
				<Route path="/project" element={<Project/>}/>
				<Route path="/area" element={<AreaInfo/>}/>
				<Route path="/links" element={<Contact/>}/>
				<Route path="/news" element={<News/>}/>
				<Route path="/idea" element={<Idea/>}/>
				<Route path="/team" element={<Team/>}/>
				<Route path="/albums/:type/:albumsCategoryId" element={<PhotoAlbums/>}/>
				<Route exact path="/albums/:type/:albumsCategoryId/:albumId" element={<PhotoAlbum/>}/>
			</Routes>
			<Footer/>
			</BrowserRouter>
		</div>
	);
}

export default App;
