import React from 'react';
import './Team.css';

const Team = () => {
  return (
    <div className="Team-page">
        <h3>Кратко описание на екипа</h3>
    </div>
  )
}

export default Team;