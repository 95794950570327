import React from 'react';
import './Idea.css';

const Idea = () => {
  return (
    <div className="Idea-page">
        <h3>Кратко описание на идеята</h3>
    </div>
  )
}

export default Idea;