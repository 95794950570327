import { useLocation } from "react-router-dom";
import Slideshow from '../../components/Slideshow/Slideshow';
import "./PhotoAlbum.css"

const PhotoAlbum = () => {
	const location = useLocation();
	const albumData = location.state?.data;
	console.log(albumData);
	
	return (
		<div className="PhotoAlbumPage">
			{albumData ?
				<><h2 className="Album-header">{albumData.name}</h2>
				<Slideshow albumData={albumData}/></>
				: <h2>Няма албум за тази категория</h2>
			}

		</div>
	)
}

export default PhotoAlbum;