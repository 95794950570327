const videos = [
    <iframe width="560" height="315" src="https://www.youtube.com/embed/xkEEGP4gwSE?si=1yCNOJmWXk-t99Q7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ewo3C9hBfS8?si=DJH37jEbS3fd9-9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/MUBVtM_BOj0?si=-Wxnaye13RhuJOjs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/R1n86CId0rE?si=fKPrhCI7HwO-qF7U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/5MOb0itEOx4?si=I7xxu1neP31J2EKS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/GMMW11wlzN4?si=IvGo0_l7siw4FlBV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/KNKQFlAJ1xs?si=SxUv6Zs6G4h8PdTB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/3rl5bFBNkR0?si=rN3TB2rWbae3XiBk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ZP54cima8t8?si=Szv1r7uhDGu4WHM-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/V8NTm7jR5c0?si=Yi1MmWpNAlFyvFtt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/M64bGDyO8OI?si=xjK4BxLtosy_nK5j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/VRTBC9d9Za4?si=d_tKJhcw5EHi4bCS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/bfxsARfqyCg?si=lPWxLyI3qxAV6nrv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/JbJQJkMmzxs?si=mc-8HNsCGG8-vZ2S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/zRfI5uhrp_Q?si=7Ma6hoB8ZYfnue0e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>,
    <iframe width="560" height="315" src="https://www.youtube.com/embed/lo4gKjW3BOw?si=dagQpxVqzObkD5ET" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
]

export default videos;