import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './PhotoAlbum.css';

const PhotoAlbums = () => {
	const [albums, setAlbums] = useState([]);
	const { type, albumsCategoryId } = useParams();

	const themeStyle = (src) => ({
		backgroundImage: 'url(' + src + ')',
		backgroundPosition: 'center'
	})

	useEffect(() => {
		const importAlbums = async () => {			
			const importedAlbums = await import(`../../data/albums/${type}/albums_${albumsCategoryId}.js`);
			setAlbums(importedAlbums.default);
		}
		importAlbums();
	}, [albumsCategoryId]);
	
	console.log(albums);
	
	return (
		<div className="Album">
			<div className="Album-list">
				{albums.map(album => (
					<Link to={`/albums/${type}/${albumsCategoryId}/${album.id}`} state={{ data: album }}>
						<li className="Album-item" style={themeStyle(`/photo_albums/${type}/${albumsCategoryId}/${album.id}/1.JPG`)}>
							<h2>{album.name}</h2>
						</li>
					</Link>
				))}
			</div>
		</div>
	)
}

export default PhotoAlbums;