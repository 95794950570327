import { Link } from "react-router-dom";
import "./Accordion.css";
import { useState } from "react";

const projectData = {
    id: 1,
    title: "За проекта",
    children: [
        {
            id: 2,
            title: "Идеята",
            link: "/idea"
        },
        {
            id: 3,
            title: "Екипът",
            link: "/team"
        },
        {
            id: 4,
            title: "Резултати",
            children: [
                {
                    id: 5,
                    title: "Публикации",
                    link: "/area"
                },
                {
                    id: 6,
                    title: "Доклади",
                    link: "/links"
                }
            ]
        },
    ]
};

export default function NavBar() {
    return (
        <ul className="NavBarList">
            <Accordion data={projectData}/>
            <li className="Navbar-item">
                <Link to={"/on-focus"}><p className="Navbar-item-text">На фокус</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/digital-resources"}><p className="Navbar-item-text">Дигитални изложби</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/catalogue"}><p className="Navbar-item-text">Тематичен каталог</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/calendar"}><p className="Navbar-item-text">Календар 2025</p></Link>
            </li>
            <li className="Navbar-item">
                <Link to={"/news"}><p className="Navbar-item-text">Новини</p></Link>
            </li>
        </ul>
    );
}

export function Accordion(props) {
    const mainNavItemStyle = (props.id == 1) ? {height: "10vh" } : {}
    const [opened, setOpened] = useState(false);
    return (
        <li className="Navbar-item" style={mainNavItemStyle}>
            <Link to={props.data.link}>
                <p className={"Navbar-item-text " + props.data.style} onClick={() => setOpened(!opened)}>{props.data.title}</p>
                {props.data.children && opened}
            </Link>
            <div className="Navbar-item-children">
                {props.data.children &&
                opened &&
                props.data.children.map((data, i) => (
                    <Accordion key={i} data={data} />
                ))}
            </div>
        </li>
    );
}
