import { useParams } from "react-router-dom";
import sights from "../../data/sights/sights_all"
import "./Sights.css"

const Sight = () => {
	const { sightId } = useParams();
	const sightData = sights.find(sight => sight.id == sightId);

	return (
		<div className="Sight">
			{sightData ? 
			(<>
				<div className="Sight-info">
					<h1>{sightData.name}</h1>
					<p>{sightData.description}</p>
				</div>
				<img src={process.env.PUBLIC_URL + sightData.img} alt=""/>
			</>)
			: <></>}
		</div>
	)
}

export default Sight;