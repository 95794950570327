import React from 'react';
import videos from "../../data/videos/videos";

import './OnFocus.css';

const OnFocus = () => {
	return (
		<div className="OnFocus">
			<a href="https://www.youtube.com/@MEDIACulAge" target="_blank"><h1>YouTube</h1></a>
            <ul className="VideosList">
                {videos.length && videos.map(video => (
                    <li className="VideoItem">
                        {video}
                    </li>
                ))}
            </ul> 
		</div>
	)
}

export default OnFocus;