import { Calendar } from "react-calendar";
import events from "../../data/events/events";
import "./Calendar.css";
import { useState } from "react";

const CalendarPage = () => {
	const [chosenEvent, setChosenEvent] = useState(events[0])

	const calendarDayClicked = (value) => {
		for (const event of events) {
			if (value.toString().includes(event.date))
				setChosenEvent(event)
		}
	}

	return (
		<div className="Calendar-section">
			<div className="Calendar">
				<Calendar onClickDay={(value) => calendarDayClicked(value)}/>
			</div>
			<div className="Calendar-event">
				<h1>{chosenEvent.title}</h1>
				<p>{chosenEvent.description}</p>
			</div>
		</div>
	)
}

export default CalendarPage;