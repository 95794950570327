import { Link } from "react-router-dom";
import museum from "../../assets/img/info.png"

const Marker = (props) => {
	return (
		<div className="Marker" onMouseEnter={props.onhoverin} onMouseLeave={props.onhoverout}>
			<Link to={`/sights/${props.id}`}>
			{props.showname && <p className="Marker-hover-title">{props.markerid}</p>}
			<img
				className={props.className}
				onClick={props.onClick}
				src={museum}
				lat={props.lat}
				lng={props.lng}
				style={{ fontSize: 40 }}
				alt={props.markerid}
				width={35}
				height={35}
				{...props}
			/>
			</Link>
		</div>
	)
}

export const defaultProps = {
	center: {
		lat: 42.426470703484895,
		lng: 25.63516055945506
	},
	zoom: 10
};

export default Marker;