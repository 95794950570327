// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBv_1ybCQ2tbfEEPQ9tsgJUtBxr7COcJP4",
  authDomain: "media-culage-9f5f0.firebaseapp.com",
  databaseURL: "https://media-culage-9f5f0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "media-culage-9f5f0",
  storageBucket: "media-culage-9f5f0.firebasestorage.app",
  messagingSenderId: "352073639157",
  appId: "1:352073639157:web:f1a680122c4a8c0b98d1bc",
  measurementId: "G-M0M9LB6RJQ"
};

// Initialize Firebase
const appConfig = initializeApp(firebaseConfig);
const analytics = getAnalytics(appConfig);

export default appConfig;