import { Link, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './Sights.css';

const Sights = () => {
	const [sights, setSights] = useState([]);
	const { themeId, type } = useParams();
	const location = useLocation();
	
	useEffect(() => {
		const importSights = async () => {
			await import(`../../data/sights/sights_all.js`)
			.then(importedSights => {
				const children = location.state?.data;			
				const filteredSights = importedSights.default.filter(sight => children.indexOf(sight.id) >= 0)
				setSights(filteredSights);
			})
			.catch(error => {
				console.error("Could not import sights. Error: ", error)
			})
		}

		const importSightsWithoutChildren = async () => {
			await import(`../../data/themes/${type}/cultural_heritage_${themeId}.js`)
			.then(async (importedSights) => {
				const children = importedSights.default[0].children;				
				await import(`../../data/sights/sights_all.js`)
				.then(sights => {
					const filteredSights = sights.default.filter(sight => children.indexOf(sight.id) >= 0)
					setSights(filteredSights);
				})
			})
			.catch(error => {
				console.error("Could not import sights. Error: ", error)
			})
		}
		
		if (themeId == "archeological" || themeId == "architectural") {
			importSights();
		}
		else {
			importSightsWithoutChildren();
		}

	}, [themeId]);

	return (
		<div className="Sights">
			<div className="Sights-header">
			</div>
			<ul>
				{sights.length ? sights.map((sight, id) => (
					<Link to={`/sights/${id}`}>
						<li>
							<p>{sight.name}</p>
						</li>
					</Link>
				)) : <>Няма обекти за тази категория.</>}
			</ul>
		</div>
	)
}

export default Sights;