const sights = [
	{
		id: 1,
		name: "Вила в м. Чаталка, с. Яворово, общ. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.36217959420408, 
		lng: 25.458720064398797,
		filter: "archeology"
	},
	{
		id: 2,
		name: "Севтополис, яз. Копринка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		img: "/sightImg/museum.jpg",
		lat: 42.617187114678856,
		lng: 25.306340239444687,
		filter: "archeology"
	},
	{
		id: 3,
		name: "Августа Траяна, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42711526026654,
		lng: 25.624786323251342,
		filter: "archeology"
	},
	{
		id: 4,
		name: "Антична баня в м. Дюлевец, гр. Гурково",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.669791250540285,
		lng: 25.816914765880874,
		filter: "archeology"
	},
	{
		id: 5,
		name: "Градски терми на Августа Траяна, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 0, 
		lng: 0,
		filter: "archeology"
	},
	{
		id: 6,
		name: "Римски терми при Старозагорски бани, с. Старозагорски бани, общ. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.45022857494049,
		lng: 25.495537912555708,
		filter: "archeology"
	},
	{
		id: 7,
		name: "Тракийска гробница в Момина Могила, с. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.30386999336325,
		lng: 25.221077598026056,
		filter: "archeology"
	},
	{
		id: 8,
		name: "Казанлъшка гробница, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.6256044768878,
		lng: 25.39981535515612,
		filter: "archeology"
	},
	{
		id: 9,
		name: "Гробница на Севт III в могила Голямата Косматка, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.700038269343224,
		lng: 25.333454758752662,
		filter: "archeology"
	},
	{
		id: 10,
		name: "Гробично-култов комплекс в могила Оструша, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.68956015569485,
		lng: 25.35089227341661,
		filter: "archeology"
	},
	{
		id: 11,
		name: "Храм в могила Шушманец, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.70678792150977,
		lng: 25.348640375203587,
		filter: "archeology"
	},
	{
		id: 12,
		name: "Гробница в могила Грифоните, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.70551776450779, 
		lng: 25.344750429779793,
		filter: "archeology"
	},
	{
		id: 13,
		name: "Гробница в могила Хелвеция, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.70569590446847, 
		lng: 25.346351660126267,
		filter: "archeology"
	},
	{
		id: 14,
		name: "Гробница в могила Светицата, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.6891686132879,
		lng: 25.36883606304409,
		filter: "archeology"
	},
	{
		id: 15,
		name: "Храм в могила Голяма Арсеналка, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.69979454785604, 
		lng: 25.30932011040506,
		filter: "archeology"
	},
	{
		id: 16,
		name: "Гробница в Сашова могила, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.704727345631646, 
		lng: 25.297300678183838,
		filter: "archeology"
	},
	{
		id: 17,
		name: "Гробница в Сарафова могила, гр. Крън, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.6757402010599, 
		lng: 25.38144524551576,
		filter: "archeology"
	},
	{
		id: 18,
		name: "Гробница в Славчова могила, с. Розово, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.55687360856866, 
		lng: 25.430730007365383,
		filter: "archeology"
	},
	{
		id: 19,
		name: "Тракийска гробница, с. Розово, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.588288060678266, 
		lng: 25.40678875445805,
		filter: "archeology"
	},
	{
		id: 20,
		name: "Ораната могила с куполна гробница, с. Бузовград, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.57603868454642, 
		lng: 25.38294439711408,
		filter: "archeology"
	},
	{
		id: 21,
		name: "Мъглижка гробница, гр. Мъглиж",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61527746413862, 
		lng: 25.515456895267025,
		filter: "archeology"
	},
	{
		id: 22,
		name: "Куполна гробница от римската епоха, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42471849218128,
		lng: 25.62573590032559,
		filter: "archeology"
	},
	{
		id: 23,
		name: "Крепост Голямото кале, с. Горно Ново село, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.45074278110867, 
		lng: 25.230416095701038,
		filter: "archeology"
	},
	{
		id: 24,
		name: "Крепост Карасура, с. Рупките, общ. Чирпан",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.2412417270362, 
		lng: 25.385464468265727,
		filter: "archeology"
	},
	{
		id: 25,
		name: "Крепост Асара, гр. Николаево",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.63829691694771, 
		lng: 25.793885740256325,
		filter: "archeology"
	},
	{
		id: 26,
		name: "Бузово кале, с. Бузовград, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.57216368467941, 
		lng: 25.382738280018515,
		filter: "archeology"
	},
	{
		id: 27,
		name: "Крънско кале, гр. Крън, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.69272841377254, 
		lng: 25.38295258270298,
		filter: "archeology"
	},
	{
		id: 28,
		name: "Крепост Градовете, гр. Крън, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.696550030896255, 
		lng: 25.372820614486454,
		filter: "archeology"
	},
	{
		id: 29,
		name: "Крепост в м. Алеков Баир, с. Знаменосец, общ. Раднево",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
	},
	{
		id: 30,
		name: "Ранновизантийска крепост, с. Полски Градец, общ. Раднево",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 0, 
		lng: 0,
		filter: "archeology"

	},
	{
		id: 31,
		name: "Крепост в м. Джуглата, с. Тъжа, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.67021040831254, 
		lng: 25.084134352940122,
		filter: "archeology"
	},
	{
		id: 32,
		name: "Крепост на вр. Чоева могила, с. Тъжа, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.66691241793961, 
		lng: 25.051128454788714,
		filter: "archeology"
	},
	{
		id: 33,
		name: "Халколитни медни рудници в м. Мечи кладенец, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.424795010525, 
		lng: 25.544491173284104,
		filter: "archeology"
	},
	{
		id: 34,
		name: "Малка Момина могила, с. Братя Даскалови, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 0,
		lng: 0,
		filter: "archeology"
	},
	{
		id: 35,
		name: "Читашка могила, с. Братя Даскалови, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.30785387221832, 
		lng: 25.214610725939373,
		filter: "archeology"
	},
	{
		id: 36,
		name: "Каракочова могила, с. Братя Даскалови, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.30554273245143, 
		lng: 25.215700086950907,
		filter: "archeology"
	},
	{
		id: 37,
		name: "Селска могила, с. Братя Даскалови, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.288773754577356, 
		lng: 25.213717810596414,
		filter: "archeology"
	},
	{
		id: 38,
		name: "Могила в м. Асара, гр. Гълъбово",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.15599099502356,  
		lng: 25.903208997098073,
		filter: "archeology"
	},
	{
		id: 39,
		name: "Могила Рошава Драгана, с. Мъдрец, общ. Гълъбово",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.14679849807645, 
		lng: 26.08572509136005, 
		filter: "archeology"
	},
	{
		id: 40,
		name: "Селищна могила Окръжна болница и Неолитни жилища, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42476176416806,
		lng: 25.6108119124505,
		filter: "archeology"
	},
	{
		id: 41,
		name: "Късноантична мозайка от частен римски дом, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42816951642577,
		lng: 25.6258751667549,
		filter: "archeology"
	},
	{
		id: 42,
		name: "Мозайка в сутерена на пощата, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.4262742615218,
		lng: 25.62993431585998,
		filter: "archeology"
	},
	{
		id: 43,
		name: "Некропол в м. Бостанлъка, с. Оризово, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
                lat: 42.21068733440221, 
		lng: 25.1561274697205,
		filter: "archeology"
	},
	{
		id: 44,
		name: "Могилен некропол Чаталка, с. Яворово, общ. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
	},
	{
		id: 45,
		name: "Тракийско ямно светилище при с. Малко Тръново и късноримско имение в местността Козлука, с. Малко Тръново, общ. Чирпан",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.26441891,
		lng: 25.43415756,
		filter: "archeology"
	},
	{
		id: 46,
		name: "Мегалит, с. Бузовград, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.57060359034148,
		lng: 25.376802285469115,
		filter: "archeology"
	},
	{
		id: 47,
		name: "Религиозни практики в границите на Архитектурен комплекс „Музей на религиите“, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.425177975491785,
		lng: 25.62902015087181,
		filter: "archeology"
	},
	{
		id: 48,
		name: "Многослоен обект при извора Халка Бунар, с. Горно Белево, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.24155698084679, 
		lng: 25.2189159438317,
		filter: "archeology"
	},
	{
		id: 49,
		name: "Средновековно селище и некропол, с. Златна Ливада, общ. Чирпан",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
	},
	{
		id: 50,
		name: "Обект северно от с. Черна гора, общ. Братя Даскалови",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.225348877834236, 
		lng: 25.22623489419806,
		filter: "archeology"
	},
	{
		id: 51,
		name: "Долмен, с. Главан, общ. Гълъбово",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.06758000, 
		lng: 26.14146000,
		filter: "archeology"

	},
	{
		id: 52,
		name: "Хаджиангеловата къща, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42616354886774,
		lng: 25.62858528631175,
		filter: "architecture"
	},
	{
		id: 53,
		name: "Салабашевата къща, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42567450378167,
		lng: 25.635698184285317,
		filter: "architecture"
	},
	{
		id: 54,
		name: "Къщата на д-р Кожухаров, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.425351657293554,
		lng: 25.63535856954561,
		filter: "architecture"
	},
	{
		id: 55,
		name: "Къщата на Бунарджиеви, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42867098276986,
		lng: 25.628407739225654,
		filter: "architecture"
	},
	{
		id: 56,
		name: "Бухчевата къща, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.424033637109424,
		lng: 25.630191546239462,
		filter: "architecture"
	},
	{
		id: 57,
		name: "Къщата на Досьо Вълев, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42872024845321, 
		lng: 25.629108942309138,
		filter: "architecture"
	},
	{
		id: 58,
		name: "Къщата на арх. Христо Димов, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.423136555644035,
		lng: 25.62416636082423,
		filter: "architecture"
	},
	{
		id: 59,
		name: "Къщата на фармацевта Начо Коев, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42899693306289,
		lng: 25.62983311758631,
		filter: "architecture"
	},
	{
		id: 60,
		name: "Аязмото, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.441199022935706,
		lng: 25.615820631578355,
		filter: "architecture"
	},
	{
		id: 61,
		name: "Папазовата къща, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.618079686297015,
		lng: 25.393931831626187,
		filter: "architecture"
	},
	{
		id: 62,
		name: "Шипковата къща, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61955618927885,
		lng: 25.394477982781407,
		filter: "architecture"
	},
	{
		id: 63,
		name: "Старата Шипкова къща, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61778247683807,
		lng: 25.3912517007827,
		filter: "architecture"
	},
	{
		id: 64,
		name: "Вила Роза, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.618776042179825,
		lng: 25.396051724247794,
		filter: "architecture"
	},
	{
		id: 65,
		name: "Къщата на Еньо Бончев, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.618796211417795,
		lng: 25.391752770338623,
		filter: "architecture"
	},
	{
		id: 66,
		name: "Къщата на Маньо Стайнов, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.618138791689226,
		lng: 25.390698525233876,
		filter: "architecture"
	},
	{
		id: 67,
		name: "Чирпанлиева къща, гр. Шипка",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.713141118621294,
		lng: 25.333380319417188,
		filter: "architecture"
	},
	{
		id: 68,
		name: "Джанановата къща, с. Габарево",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.62843245348486, 
		lng: 25.161517282212547,
		filter: "architecture"
	},
	{
		id: 69,
		name: "Минерална баня, с. Ягода",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.54565345295828,
		lng: 25.573286091526107,
		filter: "architecture"
	},
	{
		id: 70,
		name: "Къща музей „Гео Милев“, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42344032665627,
		lng: 25.634294873790196,
		filter: "architecture"
	},
	{
		id: 71,
		name: "Родната къща на Веселин Ханчев, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42319561967771,
		lng: 25.623989426084638,
		filter: "architecture"
	},
	{
		id: 72,
		name: "Къща на Николай Лилиев, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.422902759733866,
		lng: 25.632155368036365,
		filter: "architecture"
	},
	{
		id: 73,
		name: "Родната къща на Гео Милев, гр. Раднево",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.28967216833261,
		lng: 25.93509527852052,
		filter: "architecture"
	},
	{
		id: 74,
		name: "Литературно-художествен музей „Чудомир“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.620126760066604,
		lng: 25.398176313567113,
		filter: "architecture"
	},
	{
		id: 75,
		name: "Къща музей „Ненко Балкански“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.623794656145876,
		lng: 25.401451928768346,
		filter: "architecture"

	},
	{
		id: 76,
		name: "Къща музей „Дечко Узунов“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.6237142690317,
		lng: 25.398592298969266,
		filter: "architecture"
	},
	{
		id: 77,
		name: "Дом „Петко Стайнов“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.6177178065219,
		lng: 25.39101179873358,
		filter: "architecture"
	},
	{
		id: 78,
		name: "Къщата на Емануил Манолов, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.624163312984436,
		lng: 25.39907866688778,
		filter: "architecture"
	},
	{
		id: 79,
		name: "Родната къща на Чудомир, с. Турия, общ. Павел баня ",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.57275215264901,
		lng: 25.183236939220254,
		filter: "architecture"
	},
	{
		id: 80,
		name: "Къща музей „П. К. Яворов“, гр. Чирпан",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.20212965913308,
		lng: 25.326618054761536,
		filter: "architecture"
	},
	{
		id: 81,
		name: "Дом-паметник на връх Бузлуджа, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.735930245634066,
		lng: 25.393757257616482,
		filter: "architecture"
	},
	{
		id: 82,
		name: "Шипченски манастир ,Св. Рождество Христово“, гр. Шипка, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.71612500572231,
		lng: 25.32896495236883,
		filter: "architecture"
	},
	{
		id: 83,
		name: "Мъглижки манастир „Св. Николай“, гр. Мъглиж",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.623703490769216,
		lng: 25.542345865066206,
		filter: "architecture"
	},
	{
		id: 84,
		name: "Манастир „Св. Атанасий“, с. Златна ливада, общ. Чирпан",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.105981433739096,
		lng: 25.42433161134712,
		filter: "architecture"
	},
	{
		id: 85,
		name: "Потопената църква „Св. Атанасий“, с. Виден, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.60647184728159,
		lng: 25.238356389306446,
		filter: "architecture"
	},
	{
		id: 86,
		name: "Ески джамия (Архитектурен комплекс „Музей на религиите“), гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42517153477383,
		lng: 25.629013440412344,
		filter: "architecture"	
	},
	{
		id: 87,
		name: "Ески джамия, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.621106354755625,
		lng: 25.397594314292384,
		filter: "architecture"
	},
	{
		id: 88,
		name: "Национален парк-музей „Шипка-Бузлуджа“, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.74812716644887, 
		lng: 25.32153498318404,
		filter: "history"
	},	
	{
		id: 89,
		name: "Мемориален комплекс „Бранителите на Стара Загора“, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.43339727986187,
		lng: 25.654269721783344,
		filter: "history"
	},
	{
		id: 90,
		name: "Мавзолей-костница „19 юли 1877 г.“, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42973636531684,
		lng: 25.61692025636076,
		filter: "history"
	},
	{
		id: 91,
		name: "Паметник на Цанко Минков Комитата, гр. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.59782382034092,
		lng: 25.207712597792675,
		filter: "history"
	},
	{
		id: 92,
		name: "Паметник на Цанко Минков Комитата, с. Турия, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.57258380071391,
		lng: 25.183312769254,
		filter: "history"
	},
	{
		id: 93,
		name: "Розопроизводство",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.62347633416128,
		lng: 25.388509611877236,
		filter: "abstract"
	},
	{
		id: 94,
		name: "Лютиерство",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61783076591409,
		lng: 25.373150110609124,
		filter: "abstract"
	},
	{
		id: 95,
		name: "Бирено производство",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.44322745360556,
		lng: 25.633891810424505,
		filter: "abstract"
	},
	{
		id: 96,
		name: "Празник на розата, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.61931155910594,
		lng: 25.39312028824954,
		filter: "festival"
	},
	{
		id: 97,
		name: "Празник на розата, гр. Гурково",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.66080570211902,
		lng: 25.792214031115925,
		filter: "festival"
	},
	{
		id: 98,
		name: "Фестивал на маскарадните игри „Старци в Турия“, с. Турия, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.57360573500207,
		lng: 25.183365959202998,
		filter: "festival"
	},
	{
		id: 99,
		name: "Национален тракийски фолклорен събор „Богородична стъпка“, с. Старозагорски бани, общ. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.449829697715984,
		lng: 25.498340141690182,
		filter: "festival"
	},
	{
		id: 100,
		name: "Национално надиграване „Тъпан бие, хоро се вие“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.61930995488619,
		lng: 25.39312916408375,
		filter: "festival"
	},
	{
		id: 101,
		name: "Фолклорен фестивал от „Лазаровден до Гергьовден“, с. Черганово, общ. Павел баня",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.58554657325434,
		lng: 25.469521662551273,
		filter: "festival"
	},
	{
		id: 102,
		name: "Биорали, гр. Гурково",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.655167389582374,
		lng: 25.79131886377337,
		filter: "festival"
	},
	{
		id: 103,
		name: "Фестивал на Античността и Средновековието „От Августа Траяна до Боруй“, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.42720095206984,
		lng: 25.626532233414167,
		filter: "festival"
	},
	{
		id: 104,
		name: "Национално честване Шипченска епопея, Национален парк-музей „Шипка-Бузлуджа“, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за един музей.",
		img: "/sightImg/museum.jpg",
		lat: 42.7474204094586,
		lng: 25.321942808386343,
		filter: "festival"
	},
	{
		id: 105,
		name: "Празници в Долината на тракийските царе, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.62605164666417,
		lng: 25.39921400782001,
		filter: "festival"
	},
	{
		id: 106,
		name: "Есенни литературни дни, гр. Стара Загора",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.42734090156362,
		lng: 25.628107842507433,
		filter: "festival"
	},
	{
		id: 107,
		name: "Музикални празници „Петко Стайнов“, гр. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61771669800998, 
		lng: 25.391015156536117,
		filter: "festival"
	},
	{
		id: 108,
		name: "Чудомирови празници, гр. Павел баня и с. Турия",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.59796835522117,
		lng: 25.207352602739864,
		filter: "festival"
	},
	{
		id: 109,
		name: "Фестивал 'Open Buzludzha', паметник на връх Бузлуджа, общ. Казанлък",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.73110840257028, 
		lng: 25.398759109615273,
		filter: "festival"
	},
	{
		id: 110,
		name: "Фестивал 'We are going to Мъглиж', гр. Мъглиж",
		info: "Това е кратко описание за обекта.",
		description: "Това е по-дълго описание за една гробница.",
		img: "/sightImg/tomb.jpg",
		lat: 42.61780723972406,
		lng: 25.532604664375985,
		filter: "festival"
	}
]

export default sights;