import { Link } from "react-router-dom";
import { Accordion } from "../Accordion/Accordion"
import "./SideMenu.css";

const culturalHeritageData = {
    id: 1,
    title: "Културно наследство",
    children: [
        {
            id: 11,
            title: "Общ поглед",
            link: "/",
            style: "second"
        },
        {
            id: 12,
            title: "По вид",
            children: [
                {
                    id: 121,
                    title: "Археологическо",
                    link: "/themes/by_type/archeological",
                    style: "third"
                },
                {
                    id: 122,
                    title: "Архитектурно",
                    link: "/themes/by_type/architectural",
                    style: "third"
                },
                {
                    id: 123,
                    title: "Историческо",
                    link: "/themes/by_type/historical/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                },
                {
                    id: 124,
                    title: "Нематериално",
                    link: "/themes/by_type/abstract/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                },
                {
                    id: 125,
                    title: "Фествали и културни събития",
                    link: "/themes/by_type/festival/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 13,
            title: "По общини",
            children: [
                {
                    id: 131,
                    title: "Община Братя Даскалови",
                    link: "/themes/by_region/bratya_daskalovi/0",
                    style: "third"
                },
                {
                    id: 132,
                    title: "Община Гурково",
                    link: "/themes/by_region/gurkovo/0",
                    style: "third"
                },
                {
                    id: 133,
                    title: "Община Гълъбово",
                    link: "/themes/by_region/galabovo/0",
                    style: "third"
                },
                {
                    id: 134,
                    title: "Община Казанлък",
                    link: "/themes/by_region/kazanlak/0",
                    style: "third"
                },
                {
                    id: 135,
                    title: "Община Мъглиж",
                    link: "/themes/by_region/maglizh/0",
                    style: "third"
                },
                {
                    id: 136,
                    title: "Община Николаево",
                    link: "/themes/by_region/nikolaevo/0",
                    style: "third"
                },
                {
                    id: 137,
                    title: "Община Павел баня",
                    link: "/themes/by_region/pavel_banya/0",
                    style: "third"
                },
                {
                    id: 138,
                    title: "Община Раднево",
                    link: "/themes/by_region/radnevo/0",
                    style: "third"
                },
                {
                    id: 139,
                    title: "Община Стара Загора",
                    link: "/themes/by_region/stara_zagora/0",
                    style: "third"
                },
                {
                    id: 140,
                    title: "Община Чирпан",
                    link: "/themes/by_region/chirpan/0",
                    style: "third"
                }
            ],
            style: "second"
        },
    ],
    style: "first"
}

const photoAlbumsData = {
    id: 1,
    title: "Фотогалерия",
    children: [
        {
            id: 11,
            title: "Археология",
            children: [
                {
                    id: 116,
                    title: "Антични вили",
                    link: "/albums/archeology/ancient_cottages",
                    style: "third"
                },
                {
                    id: 111,
                    title: "Антични градове",
                    link: "/albums/archeology/ancient_cities",
                    style: "third"
                },
                {
                    id: 112,
                    title: "Гробници",
                    link: "/albums/archeology/tombs",
                    style: "third"
                },
                {
                    id: 113,
                    title: "Крепости",
                    link: "/albums/archeology/forts",
                    style: "third"
                },
                {
                    id: 114,
                    title: "Мозайки",
                    link: "/albums/archeology/mosaic",
                    style: "third"
                },
                {
                    id: 115,
                    title: "Светилища",
                    link: "/albums/archeology/temples",
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 12,
            title: "Архитектура",
            children: [
                {
                    id: 121,
                    title: "Градска архитектура",
                    link: "/albums/architecture/cities",
                    style: "third"
                },
                {
                    id: 122,
                    title: "Домове на творци и къщи музеи",
                    link: "/albums/architecture/houses",
                    style: "third"
                },
                {
                    id: 123,
                    title: "Религиозни музеи",
                    link: "/albums/architecture/religious",
                    style: "third"
                },
                {
                    id: 124,
                    title: "Дом-паметник на връх Бузлуджа",
                    link: "/albums/architecture/buzludzha",
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 13,
            title: "История",
            link: "/albums/history/single",
            style: "second"
        },
        {
            id: 14,
            title: "Фестивали и културни събития",
            link: "/albums/festival/single",
            style: "second"
        }
    ],
    style: "first"
}

const SideMenu = () => {
    return (
       <ul className="SideMenuList">
            <li className="SideMenuItem">
                <Accordion data={culturalHeritageData}/>
                <Accordion data={photoAlbumsData}/>
                <li className="Navbar-item">
                    <Link to={"/bibliography"}><p className="Navbar-item-text first">Библиографии</p></Link>
                </li>
                <li className="Navbar-item">
                    <Link to={"/links"}><p className="Navbar-item-text first">Връзки</p></Link>
                </li>
            </li>
       </ul> 
    )
}

export default SideMenu;